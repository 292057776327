import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Accordion, Link } from 'hds-react';
import ExternalLink from '../../../components/ExternalLink';
import InternalLink from '../../../components/InternalLink';
import TabsLayout from './tabs.mdx';
export const _frontmatter = {
  "slug": "/getting-started/faq/design",
  "title": "Frequently asked questions"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "design",
      "style": {
        "position": "relative"
      }
    }}>{`Design`}<a parentName="h2" {...{
        "href": "#design",
        "aria-label": "design permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <Accordion heading="What design software should I use?" headingLevel="3" theme={{
      '--header-font-size': 'var(--fontsize-heading-xs)',
      '--padding-vertical': 'var(--spacing-s)'
    }} mdxType="Accordion">
  The official design software used in the City of Helsinki are Sketch and Abstract. These are also software that the HDS supports - meaning that the design kit is shared for the Sketch software only. It is recommended to use Sketch in your project if it is possible. It ensures that the HDS team can support you in problem situations.
    </Accordion>
    <Accordion heading="Is there a HDS Figma library available?" headingLevel="3" theme={{
      '--header-font-size': 'var(--fontsize-heading-xs)',
      '--padding-vertical': 'var(--spacing-s)'
    }} mdxType="Accordion">
  The HDS only officially supports Sketch and Abstract. Figma is not officially supported and the team cannot provide any support for Figma issues. It is recommended to use Sketch and Abstract. Figma may be supported in the future but currently, there are no plans for it.
    </Accordion>
    <Accordion heading="How can I get the Helsinki Grotesk font to my design software?" headingLevel="3" theme={{
      '--header-font-size': 'var(--fontsize-heading-xs)',
      '--padding-vertical': 'var(--spacing-s)'
    }} mdxType="Accordion">
  In order to use the font in a design software, you need to have the font locally on your computer. Please refer to <ExternalLink openInNewTab openInNewTabAriaLabel="Opens in a new tab" openInExternalDomainAriaLabel="Opens a different website" href="https://helsinkisolutionoffice.atlassian.net/wiki/spaces/DD/pages/296058881/Using+Helsinki+Grotesk+fonts" mdxType="ExternalLink">the internal font documentation in Confluence</ExternalLink> and follow the desiger instructions. If you do not have access to Confluence, ask from your project’s city employee contact.
    </Accordion>
    <Accordion heading="My Abstract projects take a long time to open or save. What should I do?" headingLevel="3" theme={{
      '--header-font-size': 'var(--fontsize-heading-xs)',
      '--padding-vertical': 'var(--spacing-s)'
    }} mdxType="Accordion">
  <p>
  Most often this is caused by large Sketch file sizes. Abstract recommends Sketch files to be below 50MB in size. If you files are larger than this, here are a couple of tips to reduce files' sizes:
  <ol>
    <li><b>Reorganise your files.</b> This means creating multiple smaller files from one larger file. If many of your files use the same components, create one file to hold those components and use that file as a library. Read more about creating library files in the <ExternalLink href="https://www.abstract.com/blog/the-designers-guide-to-sketch-libraries-in-abstract" size="M" openInNewTab openInNewTabAriaLabel="Opens in a new tab" openInExternalDomainAriaLabel="Opens a different website" mdxType="ExternalLink">Abstract’s documentation.</ExternalLink></li>
    <li>In Sketch, use <b>File → Reduce File Size</b> feature. This uses multiple methods to reduce the file size.</li>
    <li><b>Check your images and compress them if needed.</b> Prefer .png and .jpeg file formats. Images are often the largest source of file sizes in Sketch projects.</li>
  </ol>
  </p>
    </Accordion>
    <Accordion heading="How to customize Sketch typography styles (for example, wants to use a font with a color that is not part of the default library)" headingLevel="3" theme={{
      '--header-font-size': 'var(--fontsize-heading-xs)',
      '--padding-vertical': 'var(--spacing-s)'
    }} mdxType="Accordion">
  <p>
  Generally, only black and white typography is recommended in the City of Helsinki services. If your project needs to use other colours (e.g. brand colours) in typography, you will quickly find out that these colour variations are not available in the shared Sketch styles and Sketch does not allow you to change the text colour without breaking the link to the original style or symbol.
        <p>{`  In this case, you need to create your text style and replace the default one with that:`}</p>
  <ol>
    <li>Create a text element and give it a style of the same size you need. E.g. if you need a heading M size text, use heading M black style.</li>
    <li>Change the colour of the inserted text.</li>
    <li>Create a new text style for this modified text. You can do this by pressing the “Create” button in the “Appearance” side menu. Give it a descriptive name, e.g. “Heading M - 03 Bussi”</li>
    <li>Now you have a custom text style that you can use in HDS components. Just override the text style with your style in the “Overrides” side menu.</li>
  </ol>
  </p>
    </Accordion>
    <Accordion heading="Which HDS component colors can I customise?" headingLevel="3" theme={{
      '--header-font-size': 'var(--fontsize-heading-xs)',
      '--padding-vertical': 'var(--spacing-s)'
    }} mdxType="Accordion">
  <p>
  First, please take a look at <InternalLink size="M" href="/foundation/design-tokens/colour" mdxType="InternalLink">the Colour token documentation</InternalLink> for general information about using the colours.
        <p>{`  There are a few rules of thumb that you can follow when customising colours:`}</p>
  <ol>
    <li>If the colour is editable in the symbol override menu in Sketch, it can be customised.</li>
    <li>If the default colour is Black or White, you can replace it with a Black, a White or a Grayscale colour.</li>
    <li>If the default colour is a brand colour, you can replace it with another brand colour.</li>
  </ol>
        <p>{`  If you are in doubt, you can always ask questions in the #designsystem Slack channel or via other HDS contact methods.`}</p>
  </p>
    </Accordion>
    <Accordion heading="Where can the icons be found in vector/PNG/etc. format?" headingLevel="3" theme={{
      '--header-font-size': 'var(--fontsize-heading-xs)',
      '--padding-vertical': 'var(--spacing-s)'
    }} mdxType="Accordion">
  You can <ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/releases/latest/download/hds-icon-kit.zip" size="M" external openInNewTab openInExternalDomainAriaLabel="Opens a different website." openInNewTabAriaLabel="Opens in a new tab." mdxType="ExternalLink">
  download the newest HDS icon kit from GitHub.</ExternalLink> The kit contains all HDS icons in SVG, PNG, EPS and PDF formats.
    </Accordion>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      